<template>
    <layout-centered max-width="800px">
        <div id="form-reset-password" class="bg-dark text-white rounded-3 shadow-lg d-flex" style="position: relative">
            <div class="logo align-self-center">
                <img :alt="appTitle" class="d-block mx-auto" src="@/assets/img/logo.png">
            </div>

            <form class="form" @submit.prevent="onResetPassword">
                <h1 class="h3 text-center">{{ $t('actions.reset-password') }}</h1>
                <p class="text-center">{{ $t('pages.reset-password.subheading') }}</p>

                <div class="mb-2">
                    <label class="form-label" for="password">{{ $t('new-password') }}</label>
                    <input id="password"
                           v-model="password"
                           :class="{ 'is-invalid': password !== passwordRepeat }"
                           :placeholder="$t('new-password')"
                           class="form-control"
                           required="required"
                           type="password">
                </div>

                <div class="mb-4">
                    <label class="form-label" for="passwordRepeat">{{ $t('repeat-password') }}</label>
                    <input id="passwordRepeat"
                           v-model="passwordRepeat"
                           :class="{ 'is-invalid': password !== passwordRepeat }"
                           :placeholder="$t('repeat-password')"
                           class="form-control"
                           required="required"
                           type="password">
                    <div v-if="password !== passwordRepeat" class="invalid-feedback">
                        {{ $t('password-doesnt-match') }}
                    </div>
                </div>

                <button :disabled="password.length === 0 || password !== passwordRepeat"
                        class="btn btn-lg w-100 btn-primary rounded-pill mb-2"
                        type="submit">
                    {{ $t('actions.submit') }}
                </button>

                <div class="text-center">
                    <button class="btn btn-link link-light"
                            type="button"
                            @click="$router.push('/sign-in');">
                        {{ $t('pages.sign-in.sign-in-instead') }}
                    </button>
                </div>

            </form>

            <component-loading v-if="loading"/>
        </div>
    </layout-centered>
</template>

<script>

import LayoutCentered from "@/layouts/LayoutCentered";
import ComponentLoading from "@/components/ui/ComponentLoading";

export default {
    name: "PagePasswordReset",
    components: {ComponentLoading, LayoutCentered},
    computed: {
        appTitle() {
            return process.env.VUE_APP_TITLE;
        }
    },
    data() {
        return {
            loading: false,
            password: "",
            passwordRepeat: ""
        }
    },
    methods: {
        async onResetPassword() {
            this.loading = true;

            this.$store.dispatch('USER/RECOVER_PASSWORD', {
                token: this.$route.query.key,
                newPassword: this.password
            })
                .then(() => {
                    this.$store.dispatch('toast/success', {
                        title: this.$tc('toast.success.password-set-successfully'),
                        message: this.$tc('toast.success.password-set-successfully')
                    });
                    this.$router.push({name: 'sign-in'});
                    this.loading = false;
                    this.$gtag.event('USER_RECOVER_PASSWORD');
                })
                .catch(() => {
                    this.$store.dispatch('toast/danger', {
                        title: this.$tc('toast.danger.error'),
                        message: this.$tc('toast.danger.password-not-set')
                    });
                    this.loading = false;
                })
        }
    }
}
</script>

<style lang="scss" scoped>
#form-reset-password {
    .logo {
        flex-basis: 50%;
        padding: 2.5rem 2.5rem 2.5rem 1.5rem;
    }

    .form {
        flex-basis: 50%;
        padding: 2.5rem 1.5rem 2.5rem 2.5rem;
    }
}
</style>
