<template>
    <div id="background" class="d-flex vh-100">
        <div id="layout-center" :style="{ maxWidth }">
            <slot/>
            <component-toast-container/>
        </div>
    </div>
</template>

<script>
import ComponentToastContainer from "@/components/ui/toast/ComponentToastContainer";

export default {
    name: "LayoutCentered",
    components: {ComponentToastContainer},
    props: {
        maxWidth: {
            type: String,
            required: false,
            default: "420px"
        }
    }
}
</script>

<style lang="scss" scoped>
#layout-center {
    width: 100%;
    margin: auto;
}

#background {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(/img/background.png);
    }
}
</style>
