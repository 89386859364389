<template>
    <div :style="{backgroundColor}"
         class="loading-overlay d-flex justify-content-center align-items-center">
        <component-spinner class="p-5" type="spinner-grow"/>
    </div>
</template>

<script>
import ComponentSpinner from "@/components/ui/ComponentSpinner";

export default {
    name: "ComponentLoading",
    components: {ComponentSpinner},
    props: {
        backgroundColor: {
            type: String,
            required: false,
            default: 'rgba(0,0,0,.6)'
        }
    }
}
</script>

<style lang="scss" scoped>
.loading-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;

    .spinner-container {
        -webkit-transform: scale(2);
        -moz-transform: scale(2);
        -ms-transform: scale(2);
        -o-transform: scale(2);
        transform: scale(2);
    }
}
</style>